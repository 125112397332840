<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width:150px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width:150px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="仓库">
        <base-select info="Store" v-model="filter.storeId"></base-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-refresh" @click="getData">刷新</el-button>
      </el-form-item>
    </el-form>

    <pl-table
      :data="data"
      stripe
      border
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclick"
    >
      <el-table-column label="单据编号" prop="billCode" show-overflow-tooltip sortable></el-table-column>
      <el-table-column label="单据日期" prop="billDate" width="100" sortable>
        <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
      </el-table-column>
      <el-table-column label="类型" prop="typeName" show-overflow-tooltip sortable></el-table-column>
      <el-table-column label="发出" prop="sourceName" show-overflow-tooltip sortable></el-table-column>
      <el-table-column label="接收" prop="targetName" show-overflow-tooltip sortable></el-table-column>
      <el-table-column label="摘要" prop="remark" show-overflow-tooltip></el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import setName from "@/common/setName";
import tableHeight from "@/common/tableHeightMixins";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect,
    BaseSelect
  },
  data() {
    return {
      filter: {
        begin: this.$moment().format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD")
      },
      data: []
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let params = { ...this.filter };
      this.$get("Allotted/GetList", params)
        .then(r => {
          r.forEach(item => {
            //1仓库到项目2仓库到仓库3项目到仓库4项目到项目
            switch (item.allottedType) {
              case 1:
                item.typeName = "仓库到项目";
                setName("Store", item, "sourceId", "sourceName");
                setName("Project", item, "targetId", "targetName");
                break;
              case 2:
                item.typeName = "仓库到仓库";
                setName("Store", item, "sourceId", "sourceName");
                setName("Store", item, "targetId", "targetName");
                break;
              case 3:
                item.typeName = "项目到仓库";
                setName("Project", item, "sourceId", "sourceName");
                setName("Store", item, "targetId", "targetName");
                break;
              case 4:
                item.typeName = "项目到项目";
                setName("Project", item, "sourceId", "sourceName");
                setName("Project", item, "targetId", "targetName");
                break;
            }
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    dblclick(row) {
      if (this.hasPermission("Allotted")) {
        this.$router.push({
          name: "AllottedItem",
          query: { id: row.id }
        });
      }
    }
  }
};
</script>

<style>
</style>